//
// modal
//

.modal-close {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 8;
  width: rem(56);
  height: rem(56);
  line-height: rem(56);
  text-align: center;
  background: 0;
  padding: 0;
  font-size: rem(28);
  border: 0;
  color: inherit;

  &:hover {
    background: rgba($black, 0.05);
  }
}


.modal-img {
  max-height: 280px;
  mask-image: linear-gradient(15deg, rgba(255,255,255,0.95) 0%,rgba(0,0,0,0.95) 25%,rgba(0,0,0,0.70) 95%);
  -webkit-mask-image: linear-gradient(15deg, rgba(255,255,255,0.95) 0%,rgba(0,0,0,0.95) 25%,rgba(0,0,0,0.70) 95%);
}
.modal-img2 {
  mask-image: linear-gradient(32deg, rgba(51,50,55,1) 0%, rgba(0,0,0,0) 64%);
  -webkit-mask-image: linear-gradient(32deg, rgba(51,50,55,1) 0%, rgba(0,0,0,0) 64%);
}
