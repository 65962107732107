// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

// bootstrap & variables
@import "bootstrap/scss/functions";
@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps"; // newly added
@import "bootstrap/scss/mixins";
@import "utilities";
@import "user";
@import "bootstrap/scss/bootstrap";
@import "bsoverride";

// components
@import "components/_index";
